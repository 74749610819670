/*
 *	COLORS
 *
 *	normal:	#A3C86D
 *	darker:	#97C15A
 *	dark:	#90BD4F
 *	darkest: #79A13D
 *	brightest: #C2E391
 */

/*! Company colors */

.bg-company {
  background-color: #FAFAFA;
  color: #111;
}

.list-info li .icon {
  color: #777;
}

.panel-heading-logo {
  margin: 30px auto 10px;
}

.panel-heading-subtitle {
  margin: 15px auto;
  color: #999;
}

.panel-heading.bg-company {
  border-bottom: 1px solid #EFF2F7;
}

/*! Dashboard */

#dashboard-logo {
  height: 45px;
  margin: 20px 20px 0 10px;
}

::selection {
  background: #A5CE68;
}

.top-header .menu-button:hover {
  background-color: #97C15A;
}

.bg-primary {
  color: #FFF;
}

.bg-primary {
  background-color: #A3C86D;
}

a.bg-primary:hover {
  background-color: #97C15A;
}

.text-primary {
  color: #A3C86D;
}

.text-grey {
  color: #EEE;
}

/*
a.text-primary:hover {
  color: #513d7c;
}
*/

.btn-primary {
  background-color: #A3C86D;
  border-color: #97C15A;
}

.btn-primary:focus,
.btn-primary:active,
.btn-primary:hover {
  background-color: #90BD4F;
  border-color: #79A13D;
  outline: none;
}

.page-signin .signin-header.bg-primary,
.page-signup .signin-header.bg-primary {
  background-color: #A3C86D;
}

.label-primary,
.badge-primary {
  background-color: #A3C86D;
}

/*! Header */

.top-header .top-nav .nav-left > li ul.dropdown-menu a:hover,
.top-header .top-nav .nav-right > li ul.dropdown-menu a:hover {
  background-color: #A3C86D;
}

/*! Dashboard */

.panel-profile .profile {
  background-color: rgba(194,227,145, 0.4);
  -webkit-box-shadow: 0 0 0 5px #C2E391, 0 0 10px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 0 0 5px #C2E391, 0 0 10px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 0 5px #C2E391, 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

.list-group-item .box-icon {
  display: block;
  float: left;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  margin-right: 10px;
  color: #FFF;
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 16px;
}

.list-group-item .box-icon:not([class*=" bg-"]) {
  background-color: #CCC;
}

.list-group-item a {
  color: inherit;
  text-decoration: underline;
}

/*! List View */

.table-dynamic .table-bordered thead th .fa-chevron-up.active,
.table-dynamic .table-bordered thead th .fa-chevron-down.active,
.table-dynamic .table-bordered thead th .fa-chevron-up:hover,
.table-dynamic .table-bordered thead th .fa-chevron-down:hover {
  color: #97C15A;
}

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus,
.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > span:hover,
.pagination > li > span:focus {
  background-color: #A3C86D;
  border-color: #97C15A;
}

.table-hover > tbody > tr:hover > td {
  background-color: #F0FBDF;
}

/*! Input Focus */

.form-control:focus,
input:focus,
textarea:focus {
  border-color: #A3C86D !important;
}

/*! Datepicker */

.dropdown-menu.timepicker .btn-default,
.dropdown-menu.datepicker .btn-default {
  background-color: #FFF;
  border-color: #FFF;
  color: #000;
}

.dropdown-menu.datepicker .btn-info {
  color: #fff;
  background-color: #7ACBEE;
  border-color: #63c2eb;
}

.dropdown-menu.timepicker .btn-primary,
.dropdown-menu.datepicker .btn-primary {
  color: #fff;
  background-color: #8cba48;
  border-color: #79a13d;
}

.dropdown-menu.timepicker {
  padding: 0 2px !important;
}

/*! Tabs */

.wizard-steps .progress-bar {
  background-color: #A3C86D;
}

.wizard-steps .step.current .number {
  color: #A3C86D;
}

/*! Personal Style */

.panel-box .panel-item {
  cursor: pointer;
}

.panel-box p.text-1-rows {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  /* number of lines to show */
}

.panel-box p.size-h3.text-1-rows {
  line-height: 28px;
  /* fallback */
  max-height: 30px;
  /* line-height * line-clamp */
}

.control-label.text-left {
  text-align: left;
}

.add-price-button,
.remove-price-button {
  cursor: pointer;
}

.add-price-button:hover,
.add-price-button:active,
.remove-price-button:hover,
.remove-price-button:active {
  text-decoration: none;
  color: #FFF;
}

.bg-price-highlighted {
  background-color: #f0fbdf;
  padding: 10px 0 1px;
}